import { StyledText, Text } from '$shared/components';
import { breakpoints } from '~/theme/breakpoints';
import styled from '@emotion/styled';

export const StyledHeader = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr max-content 1fr',

    justifyContent: 'space-evenly',
    marginBottom: 0,
    alignItems: 'start',
    gap: theme.space['2'],
    justifyItems: 'start',
    [breakpoints.xs]: {
        gridTemplateColumns: 'auto',
    },
}));

export const StyledHeadline = styled(StyledText, {
    [breakpoints.xs]: {
        maxWidth: '85%',
    },
});

export const StyledSubheadlineText = styled(Text)(() => ({
    textTransform: 'unset',
}));

export const StyledHeadlines = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.xs]: {
        gap: theme.space['2'],
    },
}));

export const SideContainer = styled.div({
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    flexGrow: 1,
    [breakpoints.xs]: {
        justifyContent: 'center',
    },
});
