import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { ThemeType } from '$theme';

type GridLayoutProps = {
    flow?: 'row' | 'column';
    rows?: string | string[];
    rowSize?: string | string[];
    columns?: string | string[];
    columnSize?: string | string[];
    columnGap?: 'none' | 'extrasmall' | 'small' | 'medium' | 'large';
    rowGap?: 'none' | 'extrasmall' | 'small' | 'medium' | 'large';
};

type Breakpoints = { [key: string]: GridLayoutProps };

type ConvertedBreakpoint = {
    gridTemplateRows?: string | string[];
    gridTemplateColumns?: string | string[];
    gridAutoRows?: string | string[];
    gridAutoColumns?: string | string[];
    gridAutoFlow?: 'row' | 'column';
    columnGap?: string;
    rowGap?: string;
};

type ConvertedBreakpoints = { [key: string]: ConvertedBreakpoint };

type Props = {
    flow: 'row' | 'column';
    breakpoints?: Breakpoints;
    theme?: ThemeType;
    inline?: boolean;
} & GridLayoutProps;

const convertGap = (
    theme: ThemeType | undefined,
    gap?: 'none' | 'extrasmall' | 'small' | 'medium' | 'large'
) => {
    if (!gap || !theme) {
        return undefined;
    }

    switch (gap) {
        case 'none':
            return '0px';
        case 'extrasmall':
            return theme.space[2];
        case 'small':
            return theme.space[3];
        case 'medium':
            return theme.space[4];
        case 'large':
            return theme.space[5];
    }
};

const convertBreakpoints = (theme: ThemeType | undefined, s: Breakpoints | undefined) => {
    const result: ConvertedBreakpoints = {};
    if (!s) return result;

    Object.keys(s).forEach((key) => {
        const b = s[key];
        result[key] = {
            gridTemplateRows: b.rows,
            gridTemplateColumns: b.columns,
            gridAutoRows: b.rowSize,
            gridAutoColumns: b.columnSize,
            gridAutoFlow: b.flow,
            columnGap: convertGap(theme, b.columnGap),
            rowGap: convertGap(theme, b.rowGap),
        };
    });

    return result;
};

export const Grid = styled.div<Props>(
    ({ rows, columns, flow, columnSize, rowSize, breakpoints, theme, inline }: Props) => ({
        display: inline ? 'inline-grid' : 'grid',
        gridTemplateRows: rows,
        gridTemplateColumns: columns,
        gridAutoRows: rowSize,
        gridAutoColumns: columnSize,
        gridAutoFlow: flow,
        ...convertBreakpoints(theme, breakpoints),
    }),
    switchProp('columnGap', {
        none: {
            columnGap: 0,
        },
        extrasmall: ({ theme: { space } }) => ({
            columnGap: space[2],
        }),
        small: ({ theme: { space } }) => ({
            columnGap: space[3],
        }),
        medium: ({ theme: { space } }) => ({
            columnGap: space[4],
        }),
        large: ({ theme: { space } }) => ({
            columnGap: space[5],
        }),
    }),
    switchProp('rowGap', {
        none: {
            rowGap: 0,
        },
        extrasmall: ({ theme: { space } }) => ({
            rowGap: space[2],
        }),
        small: ({ theme: { space } }) => ({
            rowGap: space[3],
        }),
        medium: ({ theme: { space } }) => ({
            rowGap: space[4],
        }),
        large: ({ theme: { space } }) => ({
            rowGap: space[5],
        }),
    })
);

export default Grid;
