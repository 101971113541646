import { theme } from '$theme';
import React from 'react';
import { Headline, Link as LinkType } from '~/lib/data-contract';
import { SideContainer, StyledHeader, StyledHeadlines, StyledSubheadlineText } from './styled';
import { Gutter, Link, Text } from '$shared/components';
import { Button } from '$shared/components/Button/Button';
import Flex from '~/shared/components/Flex';

type ModuleHeaderProps = {
    headline?: Headline;
    subHeadline?: Headline;
    subSubHeadline?: Headline;
    link?: LinkType;
    textColor?: string;
    noGutter?: boolean;
};
export const ModuleHeader = ({
    headline,
    subHeadline,
    subSubHeadline,
    link,
    textColor,
    noGutter,
}: ModuleHeaderProps) => {
    const headlineIsCampaign = headline?.style?.includes('Campaign');
    return (
        <Gutter gutterSize={noGutter ? 'none' : 'small'}>
            {!!headline && (
                <StyledHeader>
                    <SideContainer />
                    <Flex alignItems="center" justifyContent="center" grow>
                        <StyledHeadlines>
                            {headline?.text && (
                                <Text
                                    as={headline.tag}
                                    variant={headline.style}
                                    children={headline.text}
                                    color={headline.textColor || textColor}
                                    data-testid="StyledHeadline"
                                />
                            )}
                            {subHeadline?.text && (
                                <StyledSubheadlineText
                                    as={subHeadline.tag}
                                    variant={subHeadline.style}
                                    children={subHeadline.text}
                                    color={subHeadline.textColor || textColor}
                                    style={{
                                        marginTop: headlineIsCampaign
                                            ? theme.space[3]
                                            : theme.space[1],
                                    }}
                                />
                            )}
                            {subSubHeadline?.text && (
                                <StyledSubheadlineText
                                    as={subSubHeadline.tag}
                                    variant={subSubHeadline.style}
                                    children={subSubHeadline.text}
                                    color={subSubHeadline.textColor || textColor}
                                    style={{
                                        marginTop: headlineIsCampaign
                                            ? theme.space[3]
                                            : theme.space[1],
                                    }}
                                />
                            )}
                        </StyledHeadlines>
                    </Flex>
                    <SideContainer>
                        {link?.url ? (
                            <Button
                                as={Link}
                                variant="link"
                                href={link.url}
                                children={link.text}
                                title={link.title}
                                target={link.target}
                                color={textColor}
                            />
                        ) : null}
                    </SideContainer>
                </StyledHeader>
            )}
        </Gutter>
    );
};
